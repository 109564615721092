import type { NextPage } from 'next';
import { Grid } from '@mui/material';
import Image from 'next/image';

import { Policy } from 'ui';

import useUser from '../auth/useUser';
import logoGascaribe from '../public/images/Logo_Gases_Two_lines.svg';
import logoGuajira from '../public/images/Logo_gases_guajira_full.svg';
import logoEfigas from '../public/images/Logo_efigas_full.svg';
import CompanyButton from '../components/CustomComponents/CompanyButton';

const Home: NextPage = () => {
  const currentUser = useUser();

  return (
    <Grid
      container
      display="flex"
      direction="column"
      alignItems="center"
      marginTop={5}
    >
      <Policy user={currentUser} roles={['get_mercury_GDC']}>
        <Grid item>
          <CompanyButton link="/gascaribe">
            <Image src={logoGascaribe} alt="Gascaribe" width={230} />
          </CompanyButton>
        </Grid>
      </Policy>
      <Policy user={currentUser} roles={['get_mercury_EFG']}>
        <Grid item>
          <CompanyButton link="/efigas">
            <Image src={logoEfigas} alt="Efigas" width={230} />
          </CompanyButton>
        </Grid>
      </Policy>
      <Policy user={currentUser} roles={['get_mercury_GDG']}>
        <Grid item>
          <CompanyButton link="/gasguajira">
            <Image src={logoGuajira} alt="Gasguajira" width={230} />
          </CompanyButton>
        </Grid>
      </Policy>
    </Grid>
  );
};

export default Home;
